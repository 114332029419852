import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const contentManagementSlice = createSlice({
  name: 'isExpired',
  initialState,
  reducers: {
    toggleContentState: (state) => !state, // Toggles the state between true and false
    setContentState: (state, action) => action.payload, // Explicitly set state to true or false
  },
});

export const { toggleContentState, setContentState } = contentManagementSlice.actions;

export default contentManagementSlice.reducer;
